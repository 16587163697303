import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import Accordion from "../components/Accordion";
import VerticalAccordion from "../components/VerticalAccordion";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/kas-ir-rure.css";
import { COLORS } from "../utils/consts";

export default function WhatIsRure() {
	const isVertical = getIsVertical();

	return (
		<Layout pageId={PageId.WhatIsRure}>
			<Seo
				title="Kas ir Rūre?"
				description="Rūre ir jauna veselības un aprūpes sistēma, kas vienuviet sniedz kā visaptverošus pakalpojumus senioriem, tā augstas kvalitātes paliatīvo aprūpi, piedāvā mobilo paliatīvo aprūpi mājās un citus medicīniskos pakalpojumus."
			/>
			<div className="contentBlock topTextSection">
				<h1>Rūre ir jauna veselības un aprūpes sistēma, kas vienuviet sniedz kā visaptverošus pakalpojumus senioriem, tā augstas kvalitātes paliatīvo aprūpi, piedāvā mobilo paliatīvo aprūpi mājās un citus medicīniskos pakalpojumus.</h1>
			</div>
			<div className="contentBlock noPadding">
				<StaticImage
					src="../images/kas-ir-rure/1.png"
					quality={95}
					formats={["auto", "webp", "avif"]}
					alt="Rūre komanda"
					placeholder="blurred"
					loading="eager"
				/>
			</div>
			<div className="contentBlock noPadding">
				{
					isVertical
						? <div>
							<div style={{ borderBottom: `1px solid ${COLORS.darkGreen}`, padding: "5rem 0" }}>
								<p style={{ margin: 0, fontSize: "3rem" }}>Sazinies ar mums!</p>
							</div>
							<Accordion items={accordionItems} showArrow={false} titleStyle={{ borderBottom: `1px solid ${COLORS.darkGreen}`, marginBottom: "-1px", justifyContent: "center" }} />
						</div>
						: <VerticalAccordion items={verticalAccordionItems} />
				}
			</div>
			<div className="contentBlock partners">
				<p>Rūre sadarbības partneri</p>
				<div className="logos">
					<StaticImage
						src="../images/partners/aima-logo.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Aima"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/arbor-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Arbor"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/altum-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Altum"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/Liepaja-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Liepaja"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/plutos-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Plutos"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/imre-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Imre"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/baltic-lands-logo.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Baltic lands"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/rigas-zile-logo.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Rigas zīle"
						placeholder="blurred"
						objectFit="contain"
					/>
					{isVertical ? null : <div />} {/* empty placeholder */}
				</div>
				<div className="contractTable">
					<table>
						<thead>
							<tr>
								<td
									style={{
										textAlign: "center",
										fontSize: isVertical ? "1.7rem" : "1.1rem",
										lineHeight: 1,
										fontWeight: "bold",
									}}
								>SIA Magnum Social & Medical Care</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{
									paddingLeft: "1rem",
									paddingRight: "1rem",
									textAlign: "center",
									fontSize: isVertical ? "1.2rem" : "0.8rem",
									lineHeight: 1.3,
								}}>
									ir noslēdzis 30.11.2022. līgumu Nr. SKV-L-2022/68 ar Latvijas Investīciju un attīstības aģentūru par atbalsta saņemšanu pasākuma “Starptautiskās konkurētspējas veicināšana” ietvaros, ko līdzfinansē Eiropas Reģionālās attīstības fonds
								</td>
							</tr>
							<tr>
								<td style={{
									padding: 0,
								}}>
									<StaticImage
										src="../images/partners/NAP-ES.png"
										quality={95}
										formats={["auto", "webp", "avif"]}
										alt="NAP ES"
										placeholder="none"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Layout>
	);
}

const verticalAccordionItems = [
	{
		title: getIsVertical() ? "Mobīlā paliatīvā aprūpe" : "Mobīlā paliatīvā aprūpe pacienta dzīves vietā",
		Content: (
			<div className="accordionContentContainer">
				<div>
					<div>
						{getIsVertical() ? null : <p className="contactUsTop">Sazinies ar mums!</p>}
						<h2>Mobīlā paliatīvā <br />aprūpe pacienta <br />dzīves vietā</h2>
					</div>
					<div>
						<p><b>Rīga</b></p>
						<h2>Tel. nr. <a href="tel:+3718884">8884</a> <br /><a href="mailto:info.riga@rure.lv">info.riga@rure.lv</a></h2>
					</div>
				</div>
				<div>
					<div>
						<p>Kurzemes reģions</p>
						<p><b>Liepāja, Talsi, Ventspils, <br />Kuldīga, Tukums, Saldus</b></p>
						<h2>Tel. nr. <a href="tel:+3718882">8882</a> <br /><a href="mailto:info.kurzeme@rure.lv">info.kurzeme@rure.lv</a></h2>
					</div>
					<div>
						<p>Latgales reģions</p>
						<p><b>Daugavpils, Rēzekne</b></p>
						<h2>Tel. nr. <a href="tel:+3718886">8886</a> <br /><a href="mailto:info.latgale@rure.lv">info.latgale@rure.lv</a></h2>
					</div>
				</div>
			</div>
		),
	},
	{
		title: "Veselības centrs Liepāja",
		Content: (
			<div className="accordionContentContainer">
				<div>
					{getIsVertical() ? null : <p className="contactUsTop">Sazinies ar mums!</p>}
					<div className="vclContainer">
						<h2>Veselības {getIsVertical() ? "" : <br />}centrs {getIsVertical() ? "" : <br />}Liepājā</h2>
						<div>
							<h2 style={{ marginBottom: "4rem" }}><a href="mailto:info@rure.lv">info@rure.lv</a></h2>
							<h2 style={{ marginBottom: "0.3rem" }}>Tel.nr. <a href="tel:+37129396285">+371 293 962 85</a></h2>
							<p>darba dienās 9:00-17:00</p>
							<h2 style={{ marginTop: "5rem" }}>Labraga iela 11, <br />Liepāja, LV-3414</h2>
						</div>
					</div>
				</div>
			</div>
		),
	},
	{
		title: "Medicīniskie pakalpojumi un noma",
		Content: (
			<div className="accordionContentContainer">
				<div>
					<div>
						{getIsVertical() ? null : <p className="contactUsTop">Sazinies ar mums!</p>}
						<h2>Medicīniskie {getIsVertical() ? "" : <br />}pakalpojumi <br />un noma</h2>
					</div>
					<div>
						<p><b>Rīga</b></p>
						<h2>Tel. nr. <a href="tel:+3718884">8884</a> <br /><a href="mailto:info.riga@rure.lv">info.riga@rure.lv</a></h2>
					</div>
				</div>
				<div>
					<div>
						<p>Kurzemes reģions</p>
						<p><b>Liepāja, Talsi, Ventspils, <br />Kuldīga, Tukums, Saldus</b></p>
						<h2>Tel. nr. <a href="tel:+3718882">8882</a> <br /><a href="mailto:info.kurzeme@rure.lv">info.kurzeme@rure.lv</a></h2>
					</div>
					<div>
						<p>Latgales reģions</p>
						<p><b>Daugavpils, Rēzekne</b></p>
						<h2>Tel. nr. <a href="tel:+3718886">8886</a> <br /><a href="mailto:info.latgale@rure.lv">info.latgale@rure.lv</a></h2>
					</div>
				</div>
			</div>
		),
	},
	{
		title: "Par projekta attīstību un sadarbībām",
		Content: (
			<div className="accordionContentContainer">
				<div>
					{getIsVertical() ? null : <p className="contactUsTop">Sazinies ar mums!</p>}
					<div className="vclContainer">
						<h2>Par projekta {getIsVertical() ? "" : <br />}attīstību un <br />sadarbībām</h2>
						<div>
							<h2 style={{ marginBottom: "3rem" }}>Sazinieties ar <br />Kristiānu Dāvi</h2>
							<a href="mailto:kristians@rure.lv"><h2>kristians@rure.lv</h2></a>
						</div>
					</div>
				</div>
			</div>
		),
	},
];

const accordionItems = verticalAccordionItems.map(item => ({
	Title: <p>{item.title}</p>,
	Content: item.Content,
}));
